<template>
  <section class="popular-categories page__block">
    <div class="container">
      <h3 class="title">{{ $t("popular") }}</h3>
      <div class="popular-categories__inner">
        <locale-router-link
          :class="['popular-categories__item', { large: index === 0, tobacco: index === 6 }]"
          :to="`catalog?category=${item.id}`"
          v-for="(item, index) in categories"
          :key="index"
        >
          <h6 class="popular-categories__title">{{ item.attributes.label }}</h6>
          <img
            class="popular-categories__img"
            :src="getImage(index + 1)"
            alt=""
          />
        </locale-router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { getAllCategories } from "@/api/category"
import LocaleRouterLink from "../language/LocaleRouterLink.vue"
export default {
  components: { LocaleRouterLink },
  name: "AppPopularCategories",
  data() {
    return {
      categories: [],
    }
  },
  created() {
    getAllCategories().then((response) => {
      this.categories = response
    })
  },
  methods: {
    getImage(img) {
      return require("@/" + `assets/img/popular-categories/popular-categories-${img}.png`)
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.title {
  font-size: 38px;
  line-height: 87.69%;
  font-family: "CeraPro Bold";
  color: #2d2f32;
}
@media (max-width: 900px) {
  .title {
    font-size: 34px;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 20px;
    /* max-width: 140px; */
  }
}
</style>
